@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Apply a base font family and smooth scrolling globally */
html, body {
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
  background-color: #0d1b2a; /* Dark background to complement the theme */
  color: #f0f0f0;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

/* Utility classes */
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  margin-bottom: 1rem;
}

/* Links */
a {
  text-decoration: none;
  color: inherit;
}

/* Buttons */
button {
  font-family: 'Roboto', sans-serif;
}

/* Flexbox Utility */
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add any additional global styles here */

/* Scrollbar styling for better aesthetics */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #1b263b;
}

::-webkit-scrollbar-thumb {
  background-color: #415a77;
  border-radius: 10px;
  border: 3px solid #1b263b;
}